a<template>
  <svg
    version="1.1"
    class="ebba-logo"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="300px"
    height="50px"
    viewBox="0 0 304.2 97.7"
    style="enable-background: new 0 0 304.2 97.7"
    xml:space="preserve"
    :class="{ 'ebba-logo--inverted' : inverted}"
  >
    <circle class="dot" cx="286.4" cy="76.3" r="12.6" />
    <path
      class="letter"
      d="M249.4,74.2l7.9,4.2c-0.1,0.2-0.2,0.6-0.3,1c-0.1,0.4-0.6,1.2-1.2,2.3c-0.7,1.1-1.4,2-2.3,2.8
	c-0.8,0.8-2.1,1.5-3.6,2.2c-1.6,0.7-3.3,1-5.2,1c-5.9,0-10-2.7-12.3-8.1c-3.4,5.4-9.1,8.1-17.2,8.1c-6.2,0-11.2-1.5-14.8-4.6
	c-3.7-3-5.5-7.1-5.5-12.3c0-2.9,0.5-5.4,1.7-7.5c1.1-2.2,2.7-3.9,4.8-5.2c2.1-1.3,4.2-2.4,6.3-3.2c2.1-0.8,4.7-1.5,7.6-1.9
	c3-0.4,5.4-0.7,7.2-0.8c1.8-0.1,4-0.2,6.4-0.3c-0.2-5.8-3.3-8.7-9.4-8.7c-1.5,0-2.8,0.2-3.9,0.5c-1.1,0.3-2,0.8-2.5,1.3
	c-0.5,0.5-1,1-1.4,1.5c-0.4,0.5-0.6,0.9-0.7,1.3l-0.1,0.4h-13.5c0.5-5,2.8-9,6.7-12c4-3,9.1-4.6,15.4-4.6c6.7,0,12.1,1.9,16.2,5.6
	c4.1,3.7,6.2,9.3,6.2,16.9v14.6c0,5.4,1.4,8,4.1,8c0.8,0,1.5-0.2,2.1-0.6c0.6-0.4,1-0.8,1.2-1.3L249.4,74.2z M208.7,70.3
	c0,2.9,1.6,4.7,4.7,5.6c3.7,1,7.1,0.2,10.2-2.2c3.1-2.3,4.7-5.5,4.9-9.7v-1.7c-3.1,0.1-5.6,0.3-7.5,0.5c-1.9,0.2-3.9,0.6-6.1,1.2
	c-2.2,0.5-3.8,1.3-4.8,2.4C209.2,67.4,208.7,68.7,208.7,70.3z"
    />
    <path
      class="letter"
      d="M160.9,31.5c7.1,0,13.2,2.6,18.2,7.9c5,5.3,7.5,12,7.5,20.1s-2.5,14.8-7.5,20.1
	c-5,5.3-11,7.9-18.2,7.9c-5.8,0-10.4-1.9-14-5.8h-1.6l-1.5,5.3H133V9h13.8v28.5C150.3,33.5,155,31.5,160.9,31.5z M150,70.2
	c2.2,2.6,5.1,3.9,8.8,3.9c3.7,0,6.8-1.3,9.5-3.8c2.6-2.6,4-6.1,4-10.7c0-4.5-1.3-8.1-4-10.7c-2.6-2.6-5.8-3.9-9.5-3.9
	c-3.7,0-6.6,1.3-8.8,3.9c-2.2,2.6-3.3,6.1-3.3,10.6C146.7,64.1,147.8,67.6,150,70.2z"
    />
    <path
      class="letter"
      d="M96.8,31.5c7.1,0,13.2,2.6,18.1,7.9c5,5.3,7.5,12,7.5,20.1s-2.5,14.8-7.5,20.1
	c-5,5.3-11,7.9-18.1,7.9c-5.8,0-10.5-1.9-14-5.8h-1.6l-1.5,5.3H68.9V9h13.8v28.5C86.1,33.5,90.9,31.5,96.8,31.5z M85.9,70.2
	c2.2,2.6,5.1,3.9,8.8,3.9c3.7,0,6.8-1.3,9.5-3.8c2.6-2.6,4-6.1,4-10.7c0-4.5-1.3-8.1-4-10.7c-2.6-2.6-5.8-3.9-9.5-3.9
	c-3.7,0-6.6,1.3-8.8,3.9c-2.2,2.6-3.3,6.1-3.3,10.6C82.6,64.1,83.7,67.6,85.9,70.2z"
    />
    <path
      class="letter"
      d="M22.1,73.3h37.4v13.8H7.7v-77h50.7v13.7H22.1v17.4h33V55h-33V73.3z"
    />
  </svg>
</template>
<script>
export default {
  props: {
      inverted: Boolean
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">
svg.ebba-logo {
    display: block;
    max-width: 100%;

    .dot {
        fill: #0043af;
    }
    .letter {
        fill: #2e2d2d;
    }

    &--inverted {
        .dot {
            fill: var(--accent-color-3);
        }
        .letter {
            fill: #ffffff;
        }
    }
}
</style>
